<template>
  <div>
    <v-row
      class="mt-3 pt-5"
      :style="`color: ${template.colors.inline_primary_text};`"
    >
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/verein" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-shield-edit</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          VEREINSDATEN BEARBEITEN
        </h3>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center" class="mt-4">
        <v-col cols="12">
          <v-card
            :dark="!template.light"
            :light="template.light"
            class="rounded-xl pa-4"
          >
            <v-row justify="center">
              <v-col cols="12" class="py-0">
                <v-text-field
                  filled
                  rounded
                  v-model="verein.name"
                  :success-messages="nameok"
                  :error-messages="nameerror"
                  label="Vereinsname"
                  @change="updateName()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  filled
                  rounded
                  v-model="verein.alias"
                  :success-messages="linkok"
                  :error-messages="linkexist"
                  @input="
                    linkok = ''
                    linkexist = ''
                  "
                  :hint="`Besucher können über folgenden Link einchecken: ${template.var.app_url}/${user.data.alias}`"
                  persistent-hint
                  @change="updateAlias()"
                  label="Vereins-Link"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-card
                  :color="template.colors.secondary"
                  :style="`color: ${template.colors.inline_secondary_text};`"
                  rounded="xl"
                  class="pa-4"
                >
                  <v-row justify="center">
                    <v-col cols="4" md="3" class="py-0" align-self="center">
                      <v-img :src="user.data.verein.logo" width="70%" />
                    </v-col>
                    <v-col
                      cols="8"
                      md="4"
                      class="py-0 text-center"
                      align-self="center"
                    >
                      <v-btn
                        rounded
                        large
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        @click.stop="logo.dialog = true"
                      >
                        LOGO ÄNDERN
                      </v-btn>
                      <v-dialog
                        v-model="logo.dialog"
                        :max-width="
                          $vuetify.breakpoint.mdAndUp ? '700' : 'none'
                        "
                        :overlay-opacity="
                          $vuetify.breakpoint.mdAndUp ? 0.85 : undefined
                        "
                        :hide-overlay="
                          $vuetify.breakpoint.mdAndUp ? false : true
                        "
                        :transition="
                          $vuetify.breakpoint.mdAndUp
                            ? 'dialog-transition'
                            : 'dialog-bottom-transition'
                        "
                        :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true"
                        :width="$vuetify.breakpoint.mdAndUp ? 'auto' : '100vw'"
                      >
                        <v-card>
                          <v-toolbar
                            :dark="!template.light"
                            :color="template.colors.primary"
                            :style="`color: ${template.colors.inline_primary_text};`"
                          >
                            <v-btn
                              icon
                              :dark="!template.light"
                              @click="closeDialog()"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Logo hochladen</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                              <v-btn
                                dark
                                text
                                :loading="logo.uploading"
                                @click="upload()"
                              >
                                Hochladen
                              </v-btn>
                            </v-toolbar-items>
                          </v-toolbar>
                          <v-card-text>
                            <v-row justify="center" class="my-4">
                              <v-col :cols="logo.imageData ? 12 : 10">
                                <v-image-input
                                  v-model="logo.imageData"
                                  :image-quality="0.85"
                                  clearable
                                  accept="image/*"
                                  :hideActions="logo.imageData ? false : true"
                                  :imageWidth="
                                    $vuetify.breakpoint.mdAndUp ? 256 : 192
                                  "
                                  :imageMinScaling="'contain'"
                                  :imageHeight="
                                    $vuetify.breakpoint.mdAndUp ? 256 : 192
                                  "
                                  :full-width="true"
                                  image-format="png"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                class="text-center"
                                v-if="!logo.imageData"
                              >
                                Für eine gute Darstellung wähle bitte ein Bild
                                mit mindestens 256 x 256 Pixeln.
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              :color="template.colors.primary"
                              :style="`color: ${template.colors.inline_primary_text};`"
                              :loading="logo.uploading"
                              @click="upload()"
                            >
                              Speichern
                            </v-btn>
                            <v-btn color="secondary" @click="closeDialog()">
                              Abbrechen
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <!--<v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" md="10">
                <v-select
                  filled
                  label="Template"
                  v-model="verein.template"
                  :items="designs"
                ></v-select>
              </v-col>
              <v-col cols="12" md="10">
                <v-btn
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="updateTemplate()"
                >
                  DESIGN ÜBERNEHMEN
                </v-btn>
              </v-col>-->
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="error" multi-line color="danger">
      {{ errortext }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import router from '../../../routes/index'

export default {
  name: 'Zuschauer-erfassen',
  data() {
    return {
      verein: {
        name: '',
        maxzuschauer: '',
        alias: '',
        logo: '',
        template: '',
      },
      logo: {
        dialog: false,
        uploading: false,
        imageData: '',
      },
      designs: [
        'Standard-Design',
        'Blau',
        'Dunkelgrün',
        'Gelb',
        'Hellgrün',
        'Rot',
        'Schwarz',
        'Weiss',
      ],
      teams: [],
      rollen: ['Trainer', 'Vorstand'],
      created: false,
      creating: false,
      nameok: '',
      nameerror: '',
      zsok: '',
      zserror: '',
      linkok: '',
      linkexist: '',
      valid: false,
      error: false,
      errortext: '',
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
      characters: 'a-z,A-Z,0-9',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    if (this.user.data.verein) {
      this.verein.name = this.user.data.verein.name
      this.verein.maxzuschauer = this.user.data.maxzuschauer
      this.verein.logo = this.user.data.verein.logo
      if (this.user.data.alias) {
        this.verein.alias = this.user.data.alias
      } else {
        if (this.user.data.verein.data.alias) {
          this.verein.alias = this.user.data.alias
        }
      }
      if (!this.user.data.verein.template) {
        this.verein.template = 'Standard-Design'
      } else {
        this.verein.template = this.user.data.verein.template
      }
    }
  },
  methods: {
    async updateAlias() {
      if (this.verein.alias) {
        if (this.verein.alias.match(/(\\|\/){1,}/)) {
          this.linkexist = 'Der eingegebene Link enthält unzulässige Zeichen'
          return
        }
        var snap = await firebase
          .firestore()
          .collection('User')
          .where('alias', '==', this.verein.alias)
          .get()
        if (snap.empty) {
          firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .update({
              alias: this.verein.alias,
            })
            .then(() => {
              this.linkok = 'Link wurde erfolgreich geändert'
              this.linkexist = ''
            })
        } else {
          this.linkok = ''
          this.linkexist = 'Es existiert bereits ein Link mit diesem Namen'
        }
      }
    },
    updateName() {
      if (this.verein.name) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .update({
            'verein.name': this.verein.name,
          })
          .then(() => {
            this.nameok = 'Vereinsname wurde erfolgreich geändert'
            this.nameerror = ''
          })
          .catch((error) => {
            this.nameok = ''
            this.nameerror =
              'Beim Speichern ist ein Fehler aufgetreten. Bitte versuche es später erneut.'
          })
      }
    },
    templatePreview() {
      store.dispatch('changeTemplate', this.verein.template)
    },
    updateTemplate() {
      this.templatePreview()
      if (this.verein.name) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .update({
            'verein.template': this.verein.template,
          })
          .then(() => {
            this.nameok = 'Das Design deines Vereins wurde erfolgreich geändert'
            this.nameerror = ''
          })
          .catch((error) => {
            this.nameok = ''
            this.nameerror =
              'Beim Speichern ist ein Fehler aufgetreten. Bitte versuche es später erneut.'
          })
      }
    },
    updateMaxZuschauer() {
      if (this.verein.maxzuschauer) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .update({
            maxzuschauer: this.verein.maxzuschauer,
          })
          .then(() => {
            this.zsok = 'Maximale Anzahl Zuschauer wurde erfolgreich geändert'
            this.zserror = ''
          })
          .catch((error) => {
            this.zsok = ''
            this.zserror =
              'Beim Speichern ist ein Fehler aufgetreten. Bitte versuche es später erneut.'
          })
      }
    },
    closeDialog() {
      this.logo = {
        dialog: false,
        uploading: false,
        imageData: '',
      }
    },
    upload() {
      if (this.logo.imageData) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .update({
            'verein.logo': this.logo.imageData,
          })
          .then(() => {
            this.logo.uploading = false
            this.logo.imageData = ''
            this.logo.dialog = false
          })
      }
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    back() {
      router.push('/verein')
    },
    save() {
      if (this.$refs.form.validate()) {
        this.creating = true
        var functions = firebase.app().functions('europe-west3')
        var addUser = functions.httpsCallable('erstelleVereinsnutzer')
        addUser({ user: this.new_user })
          .then((result) => {
            if (result.data.success) {
              this.created = true
            } else {
              this.error = true
              this.errortext = result.data.errortext
            }
            this.creating = false
          })
          .catch((error) => {
            this.creating = false
            this.error = true
            this.errortext = error
          })
      }
    },
  },
}
</script>
